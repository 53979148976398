<template>
    <div>
        <LoadingIndi v-if="loading" />
        <DataTable v-else :value="products" tableStyle="min-width: 50rem">
            <template #header>
                <div class="flex justify-between">
                    <h1 class="text-2xl font-normal">Products</h1>
                    <Button v-tooltip.left="'Create New Product'" @click="createForm = true" class="text-white border-0 bg-secondary" :label="$t('actions.create')" size="small" />
                </div>
            </template>
            <Column field="id" :header="$t('labels.id')"></Column>
            <Column field="image" header="Image">
                <template #body="slotProps">
                    <Image :src="slotProps.data.image?.url" alt="Image" width="160" preview />
                </template>
            </Column>
            <Column field="name" :header="$t('labels.name')"></Column>
            <Column field="type" header="Type"></Column>
            <Column field="hide" header="Hide from affiliates">
                <template #body="slotProps">
                    <span class="px-2 py-1 text-white rounded-lg" :class="slotProps.data.hide ? 'bg-danger' : 'bg-success'">{{ slotProps.data.hide ? "YES" : "NO" }}</span>
                </template>
            </Column>
            <Column field="types" header="Product Types">
                <template #body="slotProps">
                    <router-link :to="{name : 'ProductTypes' , params : {id : slotProps.data.id}}">
                        <button v-tooltip.bottom="'View Product Types'" @click="showProductTypes(slotProps.data)" class="flex items-center justify-center p-2 text-sm text-white rounded-md bg-secondary">
                            product types ( {{ slotProps.data.types?.length }} )
                        </button>
                    </router-link>
                </template>
            </Column>
            <Column field="rules" header="Rules & Regulations">
                <template #body="slotProps">
                        <router-link :to="{name : 'ProductRules' , params : {id : slotProps.data.id}}" class="inline-flex items-center border-b-2 border-label text-label">
                            <span class="material-icons-outlined">gpp_maybe</span>
                            Rules & Regulations
                        </router-link>
                </template>
            </Column>
            <Column field="analytics" header="Analytics">
                <template #body="slotProps">
                        <router-link :to="{name : 'ProductAnalytics' , params : {id : slotProps.data.id}}" class="inline-flex items-center border-b-2 border-success text-success">
                            <span class="material-icons-outlined">trending_up</span>
                            Analytics
                        </router-link>
                </template>
            </Column>
            <Column field="custom" :header="$t('labels.actions')">
                <template #body="slotProps">
                    <div class="relative flex gap-2 text-white">
                        <button v-tooltip.bottom="'Edit Product'" @click="showEditForm(slotProps.data.id)" class="flex items-center justify-center p-2 text-sm rounded-full bg-secondary">
                            <span style="font-size: 1.2rem;" class="material-icons-round">edit</span>
                        </button>
                        <button v-tooltip.bottom="'Delete Product'" @click="deleteProduct(slotProps.data.id)" class="flex items-center justify-center p-2 text-sm rounded-full bg-danger">
                            <span style="font-size: 1.2rem;" class="material-icons-round">delete</span>
                        </button>
                    </div>
                </template>
            </Column>
            <template #footer>
                <div class="flex justify-end w-full font-bold">
                    <Paginator
                        v-if="meta.total"
                        @page="onPaginate($event)"
                        :rows="meta.perPage"
                        v-model:first="meta.from"
                        :current-page-report-template="meta.current_page"
                        :totalRecords="meta.total"></Paginator>
                </div>
            </template>
        </DataTable>

        <!-- Create Form -->
        <Dialog v-model:visible="createForm" modal header="Create Product" :style="{ width: '30rem' }">
            <CreateProduct @created="refreshData" />
        </Dialog>

        <!-- Edit Form -->
        <Dialog v-model:visible="editForm" modal header="Edit Product" :style="{ width: '30rem' }">
            <EditProduct :id="editId" @edited="refreshData" />
        </Dialog>

        <!-- Product types dialog -->
        <Dialog v-model:visible="showTypes" @hide="refreshData" modal :header="selectedProduct?.name" :style="{ width: '40rem' }">
            <ProductTypes :productId="selectedProduct.id" @edited="refreshData" />
        </Dialog>
        
    </div>
</template>

<script setup>
import { ref , onMounted , watch } from 'vue';
import { useRoute , useRouter } from 'vue-router';
import { getProducts } from '@/services/commonApis.js'
import ApiService from '@/services/ApiService';
import { toast } from 'vue3-toastify';

import CreateProduct from '@/components/products/CreateProduct.vue'
import EditProduct from '@/components/products/EditProduct.vue';
import ProductTypes from '@/components/products/ProductTypes.vue';
import LoadingIndi from '@/components/LoadingIndi.vue';

//primevue component imports
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Paginator from 'primevue/paginator';
import Image from 'primevue/image';

const route = useRoute();
const router = useRouter();
const currentPage = ref(+route.query.page);
const loading = ref(true);
const products = ref([]);
const meta = ref({});
const createForm = ref(false);
const editForm = ref(false);
const editId = ref(0);
const showTypes = ref(false);
const selectedProduct = ref({});

watch(currentPage, async (newPage) => {
    fetchData(newPage)
})

const fetchData = (page) => {
    loading.value = true;
    getProducts(page).then((res) => {
        loading.value = false;
        products.value = res.data.data;
        meta.value = res.meta;
        meta.value.total = Number(meta.value.total)
        meta.value.from = Number(meta.value.from) - 1;
    }).catch(() => {
        loading.value = false;
    })
}

const onPaginate = (e) => {
    currentPage.value = e.page + 1;
    router.push({name : 'ProductsView' , query : {page : currentPage.value}})
}

const refreshData = async () => {
    createForm.value = false;
    editForm.value = false;
    fetchData(currentPage.value)
}

const showEditForm = (id) => {
    editId.value = id;
    editForm.value = true;
}

const showProductTypes = (product) => {
    showTypes.value = true;
    selectedProduct.value = product;
}

const deleteProduct = (id) => {
    const tid = toast.loading('Deleting, please wait . . .');
    ApiService.delete(`products/${id}`).then(async () => {
        toast.update(tid, {
            render: "Deleted product.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'success',
            isLoading: false,
        });
        await refreshData();
    }).catch(() => {
        toast.update(tid, {
            render: "Error deleting product.",
            autoClose: true,
            closeOnClick: true,
            closeButton: true,
            type: 'error',
            isLoading: false,
        });
    })
}

onMounted(async () => {
    fetchData(currentPage.value);
})

</script>

<style scoped>

</style>