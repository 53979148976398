<template>
  <div v-if="loading">
    <LoadingIndi />
  </div>
  <div v-else class="p-2 pb-12 text-gray-700">
    <div
      class="flex items-center justify-between w-full gap-3 mx-auto lg:w-5/6"
    >
      <div class="flex items-center">
        <router-link
          :to="{ name: 'OrdersView', query: { page: 1, perPage: 10 } }"
          class="mt-1"
        >
          <span class="material-icons-round">west</span>
        </router-link>
        <h1 class="ml-2 text-2xl">{{ $t("titles.order_details") }}</h1>
      </div>
      <button
        @click="copyOrder"
        class="inline-flex items-center px-6 py-2 text-white rounded-md hover:bg-success/80 bg-success"
      >
        <span class="material-icons-outlined">content_copy</span>
        <span class="ml-2 font-bold">{{ $t("actions.copy_order") }}</span>
      </button>
    </div>
    <div class="flex justify-end w-full gap-3 mx-auto mt-4 lg:w-5/6">
      <button @click="showLogs = true" class="underline text-primary">
        {{ $t("actions.show_logs") }}
      </button>
    </div>

    <!-- logs -->
    <Dialog
      v-model:visible="showLogs"
      modal
      header="Order Logs"
      :style="{ width: '60rem' }"
    >
      <OrderLogs
        :creator="order?.user?.name"
        :created_at="order.created_at"
        :order_id="order.id"
      />
    </Dialog>

    <!-- Basic INFO -->
    <div class="flex items-center justify-between mx-auto mt-16 lg:w-5/6">
      <h1 class="text-[1.35rem] font-semibold">
        {{ $t("titles.basic_info") }}
      </h1>
      <button
        v-if="!editBasics"
        @click="editBasics = true"
        class="inline-flex items-center py-2 pl-5 pr-6 text-white rounded h-fit bg-primary"
      >
        <span
          style="margin-right: 10px; font-size: 1.2rem"
          class="material-icons-round"
          >edit</span
        >
        {{ $t("actions.edit") }}
      </button>
    </div>
    <form
      @submit.prevent="updateBasicsInfo"
      class="w-full mx-auto mb-6 lg:w-5/6"
    >
      <div class="grid gap-4 mt-2 md:grid-cols-2">
        <div>
          <label class="block mb-1 text-label" for="name">{{
            $t("labels.name")
          }}</label>
          <InputText
            v-model="basicInfo.name"
            name="name"
            type="text"
            placeholder="Jhon Doe"
            :disabled="!editBasics"
            class="w-full disabled:bg-white"
          />
          <p v-if="errors.name" class="text-sm text-danger">
            {{ errors.name[0] }}
          </p>
        </div>
        <div>
          <label class="block mb-1" for="email">{{ $t("labels.email") }}</label>
          <InputText
            v-model="basicInfo.email"
            name="email"
            type="text"
            placeholder="-"
            :disabled="!editBasics"
            class="w-full disabled:bg-white"
          />
          <p v-if="errors.email" class="text-sm text-danger">
            {{ errors.email[0] }}
          </p>
        </div>
        <div>
          <label class="block mb-1" for="phone_no">{{
            $t("labels.phone_no")
          }}</label>
          <InputText
            v-model="basicInfo.phone_no"
            name="phone_no"
            type="text"
            placeholder="-"
            :disabled="!editBasics"
            class="w-full disabled:bg-white"
          />
          <p v-if="errors.phone_no" class="text-sm text-danger">
            {{ errors.phone_no[0] }}
          </p>
        </div>
        <div>
          <label class="block mb-1" for="fb_link">{{
            $t("labels.fb_link")
          }}</label>
          <InputText
            v-model="basicInfo.fb_link"
            name="fb_link"
            type="text"
            placeholder="-"
            :disabled="!editBasics"
            class="w-full disabled:bg-white"
          />
          <p v-if="errors.fb_link" class="text-sm text-danger">
            {{ errors.fb_link[0] }}
          </p>
        </div>
        <div>
          <label class="block mb-1" for="source_id">{{
            $t("labels.source")
          }}</label>
          <Dropdown
            v-model="basicInfo.source_id"
            :options="sources"
            option-label="name"
            option-value="id"
            filter
            optionLabel="name"
            placeholder="(Optional)"
            :disabled="!editBasics"
            class="w-full bg-white"
          />
          <p v-if="errors.source_id" class="text-sm text-danger">
            {{ errors.source_id[0] }}
          </p>
        </div>
        <div class="col-start-1">
          <label class="block mb-1 text-label" for="postal_code">{{
            $t("labels.postal_code")
          }}</label>
          <InputText
            v-model="basicInfo.postal_code"
            name="postal_code"
            type="text"
            @change="checkPostalCode"
            :disabled="!editBasics"
            placeholder="09123456789"
            class="w-full disabled:bg-white"
          />
          <p v-if="errors.postal_code" class="text-sm text-danger">
            {{ errors.postal_code[0] }}
          </p>
        </div>
        <div>
          <label class="block mb-1 text-label" for="state_id">{{
            $t("labels.state")
          }}</label>
          <Dropdown
            v-model="basicInfo.state_id"
            :options="states"
            option-label="name"
            option-value="id"
            filter
            optionLabel="name"
            placeholder="Select a state"
            :disabled="!editBasics"
            class="w-full bg-white"
          />
          <p v-if="errors.state_id" class="text-sm text-danger">
            {{ errors.state_id[0] }}
          </p>
        </div>
        <div>
          <label for="address" class="block mb-1 text-label">{{
            $t("labels.address")
          }}</label>
          <Textarea
            v-model="basicInfo.address"
            placeholder="enter full address"
            name="address"
            :disabled="!editBasics"
            rows="1"
            cols="30"
            class="w-full disabled:bg-white"
          />
          <p v-if="errors.address" class="text-sm text-danger">
            {{ errors.address[0] }}
          </p>
        </div>
        <div>
          <label class="block mb-1" for="apartment_name">{{
            $t("labels.apartment")
          }}</label>
          <InputText
            v-model="basicInfo.apartment_name"
            name="apartment_name"
            type="text"
            placeholder="(Optional)"
            :disabled="!editBasics"
            class="w-full bg-white"
          />
          <p v-if="errors.apartment_name" class="text-sm text-danger">
            {{ errors.apartment_name[0] }}
          </p>
        </div>
      </div>

      <div v-if="editBasics" class="flex items-center justify-end mt-4 mb-8">
        <span
          @click="cancelEditBasics"
          class="px-6 py-2 border rounded cursor-pointer text-secondary border-secondary"
          >{{ $t("actions.cancel") }}</span
        >
        <button
          :disabled="basicsLoading"
          class="px-6 py-2 ml-3 text-white rounded bg-secondary"
        >
          <span v-if="basicsLoading">Loading</span>
          <span v-else>{{ $t("actions.update") }}</span>
        </button>
      </div>
    </form>

    <!-- Payment INFO -->
    <div class="flex items-center justify-between mx-auto mt-16 lg:w-5/6">
      <h1 class="text-[1.35rem] font-semibold">
        {{ $t("titles.payment_info") }}
      </h1>
      <button
        v-if="!editPayment"
        @click="editPayment = true"
        class="inline-flex items-center py-2 pl-5 pr-6 text-white rounded h-fit bg-primary"
      >
        <span
          style="margin-right: 10px; font-size: 1.2rem"
          class="material-icons-round"
          >edit</span
        >
        {{ $t("actions.edit") }}
      </button>
    </div>
    <form @submit.prevent="updatePaymentInfo" class="w-full mx-auto lg:w-5/6">
      <div class="grid gap-3 mt-2 md:grid-cols-2">
        <div>
          <label class="block mb-1 text-label" for="payment_method_id">{{
            $t("labels.payment_method")
          }}</label>
          <Dropdown
            v-model="paymentInfos.payment_method_id"
            :options="paymentMethods"
            :disabled="!editPayment"
            option-label="name"
            option-value="id"
            optionLabel="name"
            filter
            placeholder="Select payment method"
            class="w-full bg-white"
          />
          <p v-if="errors.payment_method_id" class="text-sm text-danger">
            {{ errors.payment_method_id[0] }}
          </p>
        </div>
        <div>
          <label class="block mb-1 text-label" for="payment_status">{{
            $t("labels.payment_status")
          }}</label>
          <Dropdown
            v-model="paymentInfos.payment_status"
            :options="['unpaid', 'paid', 'cod', 'error change']"
            placeholder="Select payment status"
            :disabled="!editPayment"
            class="w-full bg-white"
          />
          <p v-if="errors.payment_status" class="text-sm text-danger">
            {{ errors.payment_status[0] }}
          </p>
        </div>
        <div v-if="paymentInfos.payment_status == 'cod'" class="col-start-2">
          <label class="block mb-1" for="cod_amount">{{
            $t("labels.cod_amount")
          }}</label>
          <InputText
            v-model="paymentInfos.cod_amount"
            name="cod_amount"
            type="text"
            placeholder="cod amount"
            :disabled="!editPayment"
            class="w-full bg-white"
          />
          <p v-if="errors.cod_amount" class="text-sm text-danger">
            {{ errors.cod_amount[0] }}
          </p>
        </div>
      </div>
      <div v-if="editPayment" class="flex items-center justify-end mt-4 mb-8">
        <span
          @click="cancelEditPayment"
          class="px-6 py-2 border rounded cursor-pointer text-secondary border-secondary"
          >{{ $t("actions.cancel") }}</span
        >
        <button
          :disabled="paymentLoading"
          class="px-6 py-2 ml-3 text-white rounded bg-secondary"
        >
          <span v-if="paymentLoading">Loading</span>
          <span v-else>{{ $t("actions.update") }}</span>
        </button>
      </div>
    </form>

    <!-- Delivery INFO -->
    <div class="flex items-center justify-between mx-auto mt-16 lg:w-5/6">
      <h1 class="text-[1.35rem] font-semibold">{{ $t("titles.deli_info") }}</h1>
      <button
        v-if="!editDelivery"
        @click="editDelivery = true"
        class="inline-flex items-center py-2 pl-5 pr-6 text-white rounded h-fit bg-primary"
      >
        <span
          style="margin-right: 10px; font-size: 1.2rem"
          class="material-icons-round"
          >edit</span
        >
        {{ $t("actions.edit") }}
      </button>
    </div>
    <form @submit.prevent="updateDeliveryInfo" class="w-full mx-auto lg:w-5/6">
      <div class="grid gap-4 mt-2 md:grid-cols-2">
        <div>
          <label class="block mb-1" for="delivery_agent_id">{{
            $t("labels.deli_agent")
          }}</label>
          <Dropdown
            v-model="deliveryInfos.delivery_agent_id"
            :options="deliveryAgents"
            option-label="name"
            option-value="id"
            filter
            optionLabel="name"
            placeholder="-"
            :disabled="!editDelivery"
            class="w-full bg-white"
          />
          <p v-if="errors.delivery_agent_id" class="text-sm text-danger">
            {{ errors.delivery_agent_id[0] }}
          </p>
        </div>
        <div>
          <label class="block mb-1" for="delivery_accept_time_id">{{
            $t("labels.accept_time")
          }}</label>
          <Dropdown
            v-model="deliveryInfos.delivery_accept_time_id"
            :options="acceptTimes"
            option-label="name"
            option-value="id"
            optionLabel="name"
            filter
            placeholder="-"
            :disabled="!editDelivery"
            class="w-full bg-white"
          />
          <p v-if="errors.delivery_accept_time_id" class="text-sm text-danger">
            {{ errors.delivery_accept_time_id[0] }}
          </p>
        </div>
        <div>
          <label class="block mb-1" for="delivery_date">{{
            $t("labels.deli_date")
          }}</label>
          <Calendar
            v-model="deliveryInfos.delivery_date"
            showButtonBar
            placeholder="-"
            :disabled="!editDelivery"
            class="w-full bg-white disabled:bg-white"
          />
          <p v-if="errors.delivery_date" class="text-sm text-danger">
            {{ errors.delivery_date[0] }}
          </p>
        </div>
        <div>
          <label class="block mb-1" for="delivery_accept_date">{{
            $t("labels.accept_date")
          }}</label>
          <Calendar
            v-model="deliveryInfos.delivery_accept_date"
            showButtonBar
            placeholder="-"
            :disabled="!editDelivery"
            class="w-full bg-white disabled:bg-white"
          />
          <p v-if="errors.delivery_accept_date" class="text-sm text-danger">
            {{ errors.delivery_accept_date[0] }}
          </p>
        </div>
        <div>
          <label class="block mb-1 text-label" for="payment_status">{{
            $t("labels.deli_status")
          }}</label>
          <Dropdown
            v-model="deliveryInfos.delivery_status"
            :options="[
              'ordered',
              'shipped',
              'canceled',
              'delivered',
              'return',
              'investigation',
              'absent',
              'address unknown',
              'pre order',
            ]"
            placeholder="Select delivery status"
            :disabled="!editDelivery"
            class="w-full bg-white"
          />
          <p v-if="errors.payment_status" class="text-sm text-danger">
            {{ errors.payment_status[0] }}
          </p>
        </div>
        <div>
          <label class="block mb-1" for="deli_type">{{
            $t("labels.deli_type")
          }}</label>
          <Dropdown
            v-model="deliveryInfos.delivery_type_id"
            :options="deliveryTypes"
            option-value="id"
            optionLabel="name"
            filter
            placeholder="-"
            :disabled="!editDelivery"
            class="w-full bg-white"
          />
          <p v-if="errors.delivery_type" class="text-sm text-danger">
            {{ errors.delivery_type[0] }}
          </p>
        </div>
        <div>
          <label class="block mb-1" for="tracking_code">{{
            $t("labels.tracking_code")
          }}</label>
          <InputText
            v-model="deliveryInfos.tracking_code"
            name="tracking_code"
            type="text"
            placeholder="-"
            :disabled="!editDelivery"
            class="w-full bg-white"
          />
          <p v-if="errors.tracking_code" class="text-sm text-danger">
            {{ errors.tracking_code[0] }}
          </p>
        </div>
      </div>
      <div v-if="editDelivery" class="flex items-center justify-end mt-4 mb-8">
        <span
          @click="cancelEditDelivery"
          class="px-6 py-2 border rounded cursor-pointer text-secondary border-secondary"
          >{{ $t("actions.cancel") }}</span
        >
        <button
          :disabled="deliLoading"
          class="px-6 py-2 ml-3 text-white rounded bg-secondary"
        >
          <span v-if="deliLoading">Loading</span>
          <span v-else>{{ $t("actions.update") }}</span>
        </button>
      </div>
    </form>

    <!-- activateOn -->
    <div class="flex items-center justify-between mx-auto mt-16 lg:w-5/6">
      <h1 class="text-[1.35rem] font-semibold">
        {{ $t("titles.activate_on") }}
      </h1>
      <button
        v-if="!editActivateOn"
        @click="editActivateOn = true"
        class="inline-flex items-center py-2 pl-5 pr-6 text-white rounded h-fit bg-primary"
      >
        <span
          style="margin-right: 10px; font-size: 1.2rem"
          class="material-icons-round"
          >edit</span
        >
        {{ $t("actions.edit") }}
      </button>
    </div>
    <form @submit.prevent="updateActivateOn" class="mx-auto mt-4 lg:w-5/6">
      <label class="block mb-1 text-sm">{{ $t("titles.activate_on") }}</label>
      <Calendar
        v-model="activateOn"
        showButtonBar
        placeholder="(Optional)"
        :disabled="!editActivateOn"
        class="w-full bg-white"
      />
      <p v-if="errors.activate_on" class="text-sm text-danger">
        {{ errors.activate_on[0] }}
      </p>
      <div
        v-if="editActivateOn"
        class="flex items-center justify-end mt-4 mb-8"
      >
        <span
          @click="cancelEditActivateOn"
          class="px-6 py-2 border rounded cursor-pointer text-secondary border-secondary"
          >{{ $t("actions.cancel") }}</span
        >
        <button
          :disabled="activateLoading"
          class="px-6 py-2 ml-3 text-white rounded bg-secondary"
        >
          <span v-if="activateLoading">Loading</span>
          <span v-else>{{ $t("actions.update") }}</span>
        </button>
      </div>
    </form>

    <!-- order return -->
    <div class="flex items-center justify-between mx-auto mt-16 lg:w-5/6">
      <h1 class="text-[1.35rem] font-semibold">
        {{ $t("titles.order_return") }}
      </h1>
      <button
        v-if="!editReturn"
        @click="editReturn = true"
        class="inline-flex items-center py-2 pl-5 pr-6 text-white rounded h-fit bg-primary"
      >
        <span
          style="margin-right: 10px; font-size: 1.2rem"
          class="material-icons-round"
          >edit</span
        >
        {{ $t("actions.edit") }}
      </button>
    </div>
    <form @submit.prevent="updateOrderReturn" class="mx-auto mt-4 lg:w-5/6">
      <div>
        <label class="block mb-1 text-sm">{{
          $t("labels.return_status")
        }}</label>
        <Dropdown
          v-model="orderReturn.return_status"
          :options="['no return', 'return start', 'return done']"
          placeholder="Select payment status"
          :disabled="!editReturn"
          class="w-full bg-white"
        />
        <p v-if="errors.return_status" class="text-sm text-danger">
          {{ errors.return_status[0] }}
        </p>
      </div>
      <div class="grid gap-4 mt-4 md:grid-cols-2">
        <div v-if="orderReturn.return_status == 'return start'">
          <label class="block mb-1" for="start_date">{{
            $t("labels.return_start_date")
          }}</label>
          <Calendar
            v-model="orderReturn.start_date"
            showButtonBar
            placeholder="-"
            :disabled="!editReturn"
            class="w-full bg-white disabled:bg-white"
          />
          <p v-if="errors.start_date" class="text-sm text-danger">
            {{ errors.start_date[0] }}
          </p>
        </div>
        <div v-else-if="orderReturn.return_status == 'return done'">
          <label class="block mb-1" for="done_date">{{
            $t("labels.return_done_date")
          }}</label>
          <Calendar
            v-model="orderReturn.done_date"
            showButtonBar
            placeholder="-"
            :disabled="!editReturn"
            class="w-full bg-white disabled:bg-white"
          />
          <p v-if="errors.done_date" class="text-sm text-danger">
            {{ errors.done_date[0] }}
          </p>
        </div>
        <div
          class="col-start-2"
          v-if="
            orderReturn.return_status == 'return start' ||
            orderReturn.return_status == 'return done'
          "
        >
          <label class="block mb-1" for="remark">{{
            $t("labels.remark")
          }}</label>
          <InputText
            v-model="orderReturn.return_remark"
            name="remark"
            type="text"
            placeholder="remark"
            :disabled="!editReturn"
            class="w-full bg-white"
          />
          <p v-if="errors.return_remark" class="text-sm text-danger">
            {{ errors.return_remark[0] }}
          </p>
        </div>
      </div>
      <div v-if="editReturn" class="flex items-center justify-end mt-4 mb-8">
        <span
          @click="cancelEditReturn"
          class="px-6 py-2 border rounded cursor-pointer text-secondary border-secondary"
          >{{ $t("actions.cancel") }}</span
        >
        <button
          :disabled="returnLoading"
          class="px-6 py-2 ml-3 text-white rounded bg-secondary"
        >
          <span v-if="returnLoading">Loading</span>
          <span v-else>{{ $t("actions.update") }}</span>
        </button>
      </div>
    </form>

    <div class="flex justify-between mx-auto mt-16 lg:w-5/6">
      <button
        @click="
          isAddingProduct = true;
          editProduct = true;
        "
        class="block w-1/3 py-3 text-center text-white rounded-lg cursor-pointer bg-primary"
      >
        {{ $t("actions.add_product") }}
      </button>
    </div>

    <!-- add products -->
    <Dialog
      v-model:visible="isAddingProduct"
      modal
      header="Add products to order"
      :style="{ width: '70rem' }"
    >
      <ProductSelector :initialTypes="selectedTypes" @done="addSelectedTypes" />
    </Dialog>

    <ul class="grid grid-cols-3 gap-3 py-4 mx-auto mb-3 lg:w-5/6">
      <h1 class="col-span-3 text-xl text-success">
        {{ $t("labels.total") }} - {{ total }}
      </h1>
      <li
        v-for="item in selectedTypes"
        :key="item.product_type_id"
        class="relative p-4 bg-white border border-gray-300 rounded shadow min-h-fit"
      >
        <span
          @click="unselectType(item.product_type_id, item.quantity)"
          :class="`absolute bg-white top-[-10px] right-[-10px] cursor-pointer text-danger`"
          class="material-icons-outlined"
          >cancel</span
        >
        <p class="flex items-center justify-between py-1">
          <span class="block w-1/2">{{ $t("labels.name") }}</span
          >-<span class="block w-1/2 text-end">{{ item.productName }}</span>
        </p>
        <p class="flex items-center justify-between py-1">
          <span class="block w-1/2">{{ $t("labels.product_type") }}</span
          >-<span class="block w-1/2 text-end">{{ item.typeName }}</span>
        </p>
        <p class="flex items-center justify-between py-1">
          <span class="block w-1/2">{{ $t("labels.quantity") }}</span
          >-<span class="block w-1/2 text-end">{{ item.quantity }}</span>
        </p>
        <p class="flex items-center justify-between py-1">
          <span class="block w-1/2">{{ $t("labels.amount") }}</span
          >-<span class="block w-1/2 text-end">{{
            Math.trunc(item.amount)
          }}</span>
        </p>
        <p class="flex items-center justify-between py-1">
          <span class="block w-1/2">{{ $t("labels.need_details") }}</span
          >-<span class="block w-1/2 text-end">{{
            item.need_details ? "Yes" : "No"
          }}</span>
        </p>
        <!-- <p class="flex items-center justify-between py-1">
          <span class="block w-1/2">{{ $t("labels.iccid") }}</span
          >-<span class="block w-1/2 text-end">{{ item.iccid }}</span>
        </p>
        <p class="flex items-center justify-between py-1 mb-10 line-clamp-2">
          <span class="block w-1/2">{{ $t("labels.remark") }}</span
          >-<span class="block w-1/2 text-end">{{ item.remark }}</span>
        </p> -->
        <!-- <div class="flex justify-end mt-2"> -->
        <button
          @click="
            editProductTypeFields(
              item.product_type_id,
              item.quantity,
              item.iccid,
              item.remark
            )
          "
          class="px-3 py-1 border rounded cursor-pointer float-end text-secondary right-3 bottom-3 border-secondary"
        >
          {{ $t("actions.edit") }}
        </button>
        <!-- </div> -->
      </li>
    </ul>

    <Dialog
      v-model:visible="isEditingProductType"
      modal
      header="Edit Product"
      :style="{ width: '50rem' }"
    >
      <form @submit.prevent="updateProductType" class="grid grid-cols-2 gap-4">
        <div>
          <label class="block text-label" for="quantity">{{
            $t("labels.quantity")
          }}</label>
          <InputNumber v-model="editProductType.quantity" class="w-full" />
          <p v-if="errors.quantity" class="mt-1 text-sm text-danger">
            {{ errors.quantity }}
          </p>
        </div>
        <div>
          <label class="block text-label" for="quantity">{{
            $t("labels.amount")
          }}</label>
          <InputNumber v-model="editProductType.amount" class="w-full" />
          <p v-if="errors.quantity" class="mt-1 text-sm text-danger">
            {{ errors.quantity }}
          </p>
        </div>
        <div
          class="flex items-center gap-2 px-4 py-[0.4rem] mt-[1.55rem] rounded-lg h-fit"
          style="border: 1px #94a3b8 solid"
        >
          <InputSwitch v-model="editProductType.need_details" class="mt-1" />
          <label class="block" for="need_details">{{
            $t("labels.need_details")
          }}</label>
          <p v-if="errors.need_details" class="mt-1 text-sm text-danger">
            {{ errors.need_details }}
          </p>
        </div>
        <!-- <div>
                        <label class="block" for="quantity">{{ $t('labels.iccid') }}</label>
                        <InputText
                            v-model="editProductType.iccid"
                            class="w-full"
                        />
                    </div>
                    <div>
                        <label class="block" for="quantity">{{ $t('labels.remark') }}</label>
                        <InputText
                            v-model="editProductType.remark"
                            class="w-full"
                        />
                    </div> -->
        <div class="flex justify-end w-full col-span-2 gap-2 mt-2">
          <span
            @click="
              editProductType = {};
              isEditingProductType = false;
            "
            class="px-8 py-2 rounded cursor-pointer text-primary"
            >cancel</span
          >
          <button class="px-8 py-2 text-white rounded bg-primary">
            {{ $t("actions.update") }}
          </button>
        </div>
      </form>
    </Dialog>

    <div v-if="editProduct" class="flex items-center justify-center mb-12">
      <button
        @click="cancelEditProoduct"
        class="px-6 py-2 mr-3 border rounded text-secondary border-secondary"
      >
        {{ $t("actions.cancel") }}
      </button>
      <button
        :disabled="productsLoading"
        @click="updateProduct"
        class="px-6 py-2 text-white rounded bg-secondary"
      >
        <span v-if="productsLoading">Loading</span>
        <span v-else>{{ $t("actions.update_product_list") }}</span>
      </button>
    </div>

    <!-- Product Records -->
    <div class="mx-auto mt-12 rounded-lg shadow lg:w-5/6">
      <OrderRecords />
    </div>

    <!-- Slip -->
    <div class="mx-auto mt-16 lg:w-5/6">
      <h1 class="text-[1.5rem] font-semibold text-primary mb-4">Slip</h1>
      <input @change="uploadSlip" type="file" class="hidden" id="slipUpload" />
      <button
        :disabled="slipLoading"
        @click="focusSlip"
        class="w-1/3 py-3 text-white rounded outline-none bg-secondary rign-0"
      >
        <span v-if="slipLoading">Loading</span>
        <span v-else>{{ $t("actions.upload_slip") }}</span>
      </button>
      <img v-if="slip?.url" class="w-1/3 mt-4" :src="slip?.url" alt="" />
    </div>

    <!-- Additional files -->
    <div class="mx-auto mt-16 lg:w-5/6">
      <h1 class="text-[1.5rem] font-semibold text-primary mb-4">
        Additional files
      </h1>
      <input @change="uploadFiles" type="file" class="hidden" id="fileupload" />
      <button
        @click="focusAdditionalFile"
        class="w-1/3 py-3 text-white rounded outline-none bg-secondary ring-0"
      >
        {{ $t("actions.upload_files") }}
      </button>
      <h2></h2>
      <div v-if="filesPreview?.length" style="columns: 150px" class="my-4">
        <img
          v-for="file in filesPreview"
          :key="file.id"
          class="w-full m-2"
          :src="file.url"
          alt=""
        />
      </div>
      <div
        class="flex justify-center"
        v-if="filesPreview?.length && formData.has('files[]')"
      >
        <button
          :disabled="filesLoading"
          @click="uploadAllFiles"
          class="px-6 py-3 text-white rounded outline-none h-fit bg-success rign-0"
        >
          <span v-if="filesLoading">Loading</span>
          <span v-else>{{ $t("actions.upload_all") }}</span>
        </button>
      </div>
      <div v-if="additionalFiles?.length" style="columns: 250px;" class="my-4">
        <img
          v-for="file in additionalFiles"
          :key="file.id"
          class="w-full m-2"
          :src="file.url"
          alt=""
        />
      </div>
    </div>

  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import { toast } from "vue3-toastify";

import {
  getStates,
  getAcceptTimes,
  getDeliveryAgents,
  getPaymentMethods,
  getDeliveryTypes,
  getSources,
} from "@/services/commonApis";
import ProductSelector from "@/components/products/ProductSelector.vue";
import OrderLogs from "@/components/orders/OrderLogs.vue";
import LoadingIndi from "@/components/LoadingIndi.vue";
import OrderRecords from "@/components/orders/OrderRecords.vue";

//prime vue components
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import Calendar from "primevue/calendar";
import Dialog from "primevue/dialog";
import InputSwitch from "primevue/inputswitch";

const route = useRoute();
const loading = ref(false);
const states = ref([]);
const acceptTimes = ref([]);
const deliveryAgents = ref([]);
const paymentMethods = ref([]);
const deliveryTypes = ref([]);
const sources = ref([]);

const order = ref({});
const total = ref(0);
const errors = ref({});
const showLogs = ref(false);

const basicInfo = ref({});
const paymentInfos = ref({});
const orderReturn = ref({});
const deliveryInfos = ref({});
const activateOn = ref("");
const editProductType = ref({});

const basicsLoading = ref(false);
const paymentLoading = ref(false);
const deliLoading = ref(false);
const activateLoading = ref(false);
const returnLoading = ref(false);
const productsLoading = ref(false);
const slipLoading = ref(false);
const filesLoading = ref(false);

const editBasics = ref(false);
const editPayment = ref(false);
const editReturn = ref(false);
const editDelivery = ref(false);
const editProduct = ref(false);
const editActivateOn = ref(false);
const isEditingProductType = ref(false);

const isAddingProduct = ref(false);
const selectedTypes = ref([]);

const slip = ref({});
const filesPreview = ref([]);
const additionalFiles = ref([]);
let formData = new FormData();

const copyOrder = () => {
  let formatProducts = () => {
    let text = "";
    order.value.products?.forEach((prod, index) => {
      text += `${index + 1}. ${prod.productName} (${prod.typeName}) × ${
        prod.quantity
      } \n${prod.remark ? prod.remark : ""} \n\n`;
    });
    return text;
  };
  let text = `Name - ${basicInfo.value.name}\nPhone no. - ${
    basicInfo.value.phone_no ? basicInfo.value.phone_no : ""
  }\nPostal code - ${basicInfo.value.postal_code}\nAddress - ${
    basicInfo.value.state.name
  } ${basicInfo.value.address} ${
    basicInfo.value.apartment_name ? basicInfo.value.apartment_name : ""
  }\nDelivery type - ${
    deliveryInfos.value.delivery_type
      ? deliveryInfos.value.delivery_type.name
      : ""
  }\nDelivery accept date - ${
    deliveryInfos.value.delivery_accept_date
      ? deliveryInfos.value.delivery_accept_date
      : ""
  }\nDelivery accept time - ${
    deliveryInfos.value.delivery_accept_time
      ? deliveryInfos.value.delivery_accept_time.name
      : ""
  }\n\n• Order Items List\n\n${formatProducts()}`;
  navigator.clipboard.writeText(text);
  toast.success("Copied order details to clipboard.");
};

const checkPostalCode = (e) => {
  ApiService.get(
    `https://apis.postcode-jp.com/api/v5/postcodes/${e.target.value}?apikey=fFlNHwUdI50gi3MI6nfA20S577K4E7punaX39FR`
  )
    .then((res) => {
      let pref = res[0].pref;
      if (pref) {
        order.value.address = `${res[0].city} ${res[0].town}`;
        let state = states.value.find((s) => s.name == pref);
        if (state != undefined) {
          order.value.state_id = state.id;
        }
      }
    })
    .catch(() => {});
};

const addSelectedTypes = (types) => {
  selectedTypes.value = types.value;
  total.value = 0;
  selectedTypes.value.forEach((t) => {
    total.value += t.amount;
  });
  isAddingProduct.value = false;
};
const unselectType = (id, quantity) => {
  total.value = 0;
  editProduct.value = true;
  selectedTypes.value = selectedTypes.value.filter((t) => {
    if (t.product_type_id != id) {
      total.value += t.amount;
      return true;
    } else if (t.quantity != quantity) {
      total.value += t.amount;
      return true;
    }
  });
};
const editProductTypeFields = (id, quantity, iccid, remark) => {
  let index = 0;
  const item = selectedTypes.value.find((t, i) => {
    if (
      id == t.product_type_id &&
      quantity == t.quantity &&
      iccid == t.iccid &&
      remark == t.remark
    ) {
      index = i;
      return true;
    }
  });
  if (item) {
    editProductType.value.amount = item.amount;
    editProductType.value.iccid = item.iccid;
    editProductType.value.productName = item.productName;
    editProductType.value.product_type_id = item.product_type_id;
    editProductType.value.quantity = item.quantity;
    editProductType.value.remark = item.remark;
    editProductType.value.typeName = item.typeName;
    editProductType.value.need_details = item.need_details;
    editProductType.value.index = index;
    isEditingProductType.value = true;
  }
};
const updateProductType = () => {
  let index = editProductType.value.index;
  const item = selectedTypes.value.find((t, i) => {
    if (
      editProductType.value.product_type_id == t.product_type_id &&
      index == i
    ) {
      return true;
    }
  });
  const compareData = () => {
    let itemKeys = Object.keys(item);
    for (let key of itemKeys) {
      if (item[key] !== editProductType.value[key]) {
        return true;
      }
    }
    return false;
  };
  const isDifferent = compareData();
  if (isDifferent) {
    selectedTypes.value[index].amount = editProductType.value.amount;
    selectedTypes.value[index].iccid = editProductType.value.iccid;
    selectedTypes.value[index].quantity = editProductType.value.quantity;
    selectedTypes.value[index].remark = editProductType.value.remark;
    selectedTypes.value[index].need_details =
      editProductType.value.need_details;
    isEditingProductType.value = false;
    editProduct.value = true;
  } else {
    editProductType.value = {};
    isEditingProductType.value = false;
  }
};

const convertDate = (dateString) => {
  const myanmarDate = new Date(dateString);
  const year = myanmarDate.getFullYear();
  const month = myanmarDate.getMonth() + 1;
  const day = myanmarDate.getDate();
  const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
    day < 10 ? "0" : ""
  }${day}`;
  return formattedDate;
};

const cancelEditReturn = () => {
  editReturn.value = false;
  insertReturnInfos(order.value);
};

const updateOrderReturn = () => {
  returnLoading.value = true;
  const tid = toast.loading("Updating, please wait . . .");
  orderReturn.value.start_date = orderReturn.value.start_date
    ? convertDate(orderReturn.value.start_date)
    : null;
  orderReturn.value.done_date = orderReturn.value.done_date
    ? convertDate(orderReturn.value.done_date)
    : null;
  ApiService.put(
    `orders/update-order-return/${route.params.id}`,
    orderReturn.value
  )
    .then((res) => {
      returnLoading.value = false;
      toast.update(tid, {
        render: "Updated order return.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      editReturn.value = false;
      orderReturn.value = res.data;
    })
    .catch((res) => {
      returnLoading.value = false;
      console.log(res.response);
      toast.update(tid, {
        render: res.response?.data?.message
          ? res.response?.data?.message
          : "Error updating  order return.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
      errors.value =
        res?.response?.data && res?.response?.data?.errors
          ? res?.response?.data?.errors
          : {};
    });
};

const cancelEditActivateOn = () => {
  editActivateOn.value = false;
  activateOn.value = order.value.activate_on;
};
const updateActivateOn = () => {
  activateLoading.value = true;
  const tid = toast.loading("Updating, please wait . . .");
  activateOn.value = convertDate(activateOn.value);
  ApiService.put(`orders/update-activate-on/${route.params.id}`, {
    activate_on: activateOn.value,
  })
    .then((res) => {
      activateLoading.value = false;
      toast.update(tid, {
        render: "Updated activate on date.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      activateOn.value = res.data;
      editActivateOn.value = false;
    })
    .catch((res) => {
      activateLoading.value = false;
      toast.update(tid, {
        render: "Error updating activate on date.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
      errors.value =
        res?.response?.data && res?.response?.data?.errors
          ? res?.response?.data?.errors
          : {};
    });
};

const cancelEditBasics = () => {
  editBasics.value = false;
  insertDeliveryInfos(order.value);
};
const updateBasicsInfo = () => {
  basicsLoading.value = true;
  const tid = toast.loading("Updating, please wait . . .");
  ApiService.put(`orders/update-basic-info/${route.params.id}`, basicInfo.value)
    .then((res) => {
      basicsLoading.value = false;
      toast.update(tid, {
        render: "Updated basic info.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      insertBasicInfos(res.data);
      editBasics.value = false;
    })
    .catch((res) => {
      basicsLoading.value = false;
      toast.update(tid, {
        render: "Error updating basic info.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
      errors.value =
        res?.response?.data && res?.response?.data?.errors
          ? res?.response?.data?.errors
          : {};
    });
};

const cancelEditPayment = () => {
  editPayment.value = false;
  insertPaymentInfos(order.value);
};
const updatePaymentInfo = () => {
  paymentLoading.value = true;
  const id = toast.loading("Updating, please wait . . .");
  paymentInfos.value.cod_amount =
    paymentInfos.value.payment_status == "cod"
      ? paymentInfos.value.cod_amount
      : null;
  ApiService.put(
    `orders/update-payment-info/${route.params.id}`,
    paymentInfos.value
  )
    .then((res) => {
      paymentLoading.value = false;
      toast.update(id, {
        render: "Updated payment info.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      insertPaymentInfos(res.data);
      editPayment.value = false;
    })
    .catch((res) => {
      paymentLoading.value = false;
      toast.update(id, {
        render: "Error updating payment info.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
      errors.value =
        res?.response?.data && res?.response?.data?.errors
          ? res?.response?.data?.errors
          : {};
    });
};

const cancelEditDelivery = () => {
  editDelivery.value = false;
  insertDeliveryInfos(order.value);
};
const updateDeliveryInfo = () => {
  deliLoading.value = true;
  const id = toast.loading("Updating, please wait . . .");
  if (deliveryInfos.value.delivery_accept_date) {
    deliveryInfos.value.delivery_accept_date = convertDate(
      deliveryInfos.value.delivery_accept_date
    );
  }
  if (deliveryInfos.value.delivery_date) {
    deliveryInfos.value.delivery_date = convertDate(
      deliveryInfos.value.delivery_date
    );
  }
  ApiService.put(
    `orders/update-delivery-info/${route.params.id}`,
    deliveryInfos.value
  )
    .then((res) => {
      deliLoading.value = false;
      toast.update(id, {
        render: "Updated delivery info.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      insertDeliveryInfos(res.data);
      editDelivery.value = false;
    })
    .catch((res) => {
      deliLoading.value = false;
      toast.update(id, {
        render: "Error updating delivery info.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
      errors.value =
        res?.response?.data && res?.response?.data?.errors
          ? res?.response?.data?.errors
          : {};
    });
};

const cancelEditProoduct = () => {
  editProduct.value = false;
  selectedTypes.value = order.value.products;
};
const updateProduct = () => {
  productsLoading.value = true;
  const id = toast.loading("Updating, please wait . . .");
  order.value.types = [];
  selectedTypes.value.forEach((t) => {
    let obj = {
      product_type_id: t.product_type_id,
      amount: t.amount,
      quantity: t.quantity,
      iccid: t.iccid,
      remark: t.remark,
      need_details: t.need_details,
    };
    order.value.types.push(obj);
  });
  ApiService.put(`orders/update-product-list/${route.params.id}`, {
    types: order.value.types,
  })
    .then(() => {
      productsLoading.value = false;
      toast.update(id, {
        render: "Updated products list.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      window.location.reload();
    })
    .catch((res) => {
      productsLoading.value = false;
      toast.update(id, {
        render: "Error uppdating products list.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
      errors.value =
        res?.response?.data && res?.response?.data?.errors
          ? res?.response?.data?.errors
          : {};
    });
};

const focusSlip = () => {
  document.getElementById("slipUpload").click();
};

const uploadSlip = (e) => {
  slipLoading.value = true;
  const id = toast.loading("Uploading, please wait . . .");
  let formData = new FormData();
  formData.set("slip", e.target.files[0]);
  formData.set("order_id", Number(order.value.id));
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  ApiService.post("slips", formData, headers)
    .then((res) => {
      slipLoading.value = false;
      toast.update(id, {
        render: "Uploaded slip.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      slip.value = { url: res.data };
    })
    .catch(() => {
      slipLoading.value = false;
      toast.update(id, {
        render: "Error uploading slip.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
    });
};

const focusAdditionalFile = () => {
  document.getElementById("fileupload").click();
};

const uploadFiles = (e) => {
  if (e.target.files?.length) {
    formData.append("files[]", e.target.files[0]);
    let tempUrl = URL.createObjectURL(e.target.files[0]);
    let tempId = Math.floor(Math.random() * 10);
    let tempObj = {
      id: tempId,
      url: tempUrl,
    };
    filesPreview.value.push(tempObj);
  }
};

const uploadAllFiles = () => {
  filesLoading.value = true;
  const id = toast.loading("Uploading, please wait . . .");
  formData.append("order_id", order.value.id);
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  ApiService.post("files", formData, headers)
    .then((res) => {
      filesLoading.value = false;
      toast.update(id, {
        render: "Uploaded files.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "success",
        isLoading: false,
      });
      additionalFiles.value = res.data;
      filesPreview.value = [];
      formData.delete("files[]");
    })
    .catch(() => {
      filesLoading.value = false;
      toast.update(id, {
        render: "Error uploading files.",
        autoClose: true,
        closeOnClick: true,
        closeButton: true,
        type: "error",
        isLoading: false,
      });
    });
};

const insertBasicInfos = (od) => {
  basicInfo.value.name = od.name;
  basicInfo.value.email = od.email;
  basicInfo.value.phone_no = od.phone_no;
  basicInfo.value.fb_link = od.fb_link;
  basicInfo.value.postal_code = od.postal_code;
  basicInfo.value.state_id = od.state_id;
  basicInfo.value.state = od.state;
  basicInfo.value.address = od.address;
  basicInfo.value.apartment_name = od.apartment_name;
  basicInfo.value.source_id = od.source_id;
};
const insertPaymentInfos = (od) => {
  paymentInfos.value.payment_status = od.payment_status;
  paymentInfos.value.payment_method_id = od.payment_method_id;
  paymentInfos.value.cod_amount = od.cod_amount;
};
const insertDeliveryInfos = (od) => {
  deliveryInfos.value.delivery_agent_id = od.delivery_agent_id;
  deliveryInfos.value.delivery_agent = od.delivery_agent;
  deliveryInfos.value.delivery_accept_time_id = od.delivery_accept_time_id;
  deliveryInfos.value.delivery_accept_time = od.delivery_accept_time;
  deliveryInfos.value.delivery_date = od.delivery_date;
  deliveryInfos.value.delivery_accept_date = od.delivery_accept_date;
  deliveryInfos.value.delivery_status = od.delivery_status;
  deliveryInfos.value.tracking_code = od.tracking_code;
  deliveryInfos.value.delivery_type_id = od.delivery_type_id;
  deliveryInfos.value.delivery_type = od.delivery_type;
};

const insertReturnInfos = (od) => {
  orderReturn.value = {
    return_status: od.order_return?.return_status,
    return_remark: od.order_return?.return_remark,
    start_date: od.order_return?.start_date,
    done_date: od.order_return?.done_date,
  };
};

const getData = async () => {
  await getStates(1, "all").then((res) => {
    states.value = res.data;
  });
  getPaymentMethods(1, "all").then((res) => {
    paymentMethods.value = res.data;
    loading.value = false;
  });
  getAcceptTimes(1, "all").then((res) => {
    acceptTimes.value = res.data;
  });
  getDeliveryAgents(1, "all").then((res) => {
    deliveryAgents.value = res.data;
  });
  getDeliveryTypes(1, "all").then((res) => {
    deliveryTypes.value = res.data;
  });
  getSources(1, "all").then((res) => {
    sources.value = res.data;
  });
};

onMounted(() => {
  loading.value = true;
  ApiService.get(`orders/${route.params.id}`)
    .then((res) => {
      order.value = res.data;
      insertBasicInfos(order.value);
      insertPaymentInfos(order.value);
      insertDeliveryInfos(order.value);
      insertReturnInfos(order.value);
      activateOn.value = order.value.activate_on;
      slip.value = order.value.slip;
      additionalFiles.value = order.value.files;
      order.value.products.forEach((t) => {
        total.value += t.pivot.amount;
        let obj = {
          product_type_id: t.pivot.product_type_id,
          productName: t.product.name,
          typeName: t.name,
          amount: t.pivot.amount,
          quantity: t.pivot.quantity,
          iccid: t.pivot.iccid,
          remark: t.pivot.remark,
          need_details: t.pivot.need_details,
        };
        selectedTypes.value.push(obj);
      });
      order.value.products = selectedTypes.value;
      order.value.types = [];
      getData().catch(() => {
        toast.error("Error fetching related data.");
      });
    })
    .catch(() => {
      loading.value = false;
      toast.error("Error fetching data.");
    });
});
</script>

<style scoped>
</style>